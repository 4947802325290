  <template>
    <div>
      <b-table-simple responsive borderless fixed class="table p-table">
        <thead>
          <tr>
            <th class="td-header">Data</th>
            <th class="td-header">Conta origem</th>
            <th class="td-header">Valor</th>
            <th class="td-header">Mét. operação</th>
            <th class="td-header">Conta destino</th>
            <th class="td-header">Observações</th>
            <th class="td-header"></th>
          </tr>
        </thead>
  
        <tbody>
          <tr v-for="movement in movements" :key="movement.id">
            <td class="td-body">
              {{ parseDate(movement?.date) }}
            </td>
  
            <td class="td-body">
              <Ellipsis>{{ movement?.origin?.name }}</Ellipsis>
            </td>
  
            <td class="td-body">
              <span class="value">{{ parseNumberToMoney(movement?.value) }}</span>
            </td>
  
            <td class="td-body">
              {{ movement?.method }}
            </td>
  
            <td class="td-body">
              <Ellipsis>{{ movement?.destination?.name }}</Ellipsis>
            </td>
  
            <td class="td-body">
              <Ellipsis>{{ movement?.observations }}</Ellipsis>
            </td>
  
            <td class="td-body">
              <div class="edit-icon">
                  <Icon v-can="'FiMov3'" tooltip="Editar observações" :placement="'left'">
                    <Edit class="icon" @click="() => onEdit && onEdit(movement)" />
                  </Icon>
                </div>
            </td>
          </tr>
        </tbody>
      </b-table-simple>
  
      <NoFilterResults
        v-if="!loading && !movements?.length"
        :message="'Os filtros aplicados não geraram nenhum resultado.'"
      />
  
    </div>
  </template>
  
  <script>
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  
  export default {
    props: {
      movements: { type: Array, required: true },
      onEdit: Function,
      loading: Boolean,
    },
    components: {
      Edit: () => import('@/assets/icons/edit.svg'), 
      Ellipsis: () => import('@/components/General/Ellipsis'),
      Icon: () => import('@/components/General/Icon'), 
      NoFilterResults: () => import('@/components/General/noFilterResults'),
    },  
    methods: {
      parseNumberToMoney,
      parseDate(date) {
        if (!date) return '-'
        return this.moment(date).format('DD/MM/YYYY')
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/scss/table.scss';
    .value {
      font-weight: 700;
    }
    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      stroke: var(--blue-500);
    }
    .edit-icon {
      width: 100%;
      display: flex;
      justify-content: end;
    }
  </style>
  